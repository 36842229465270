<template>
  <section class="create-organization">
    <header>
      <h1>New Organization</h1>
    </header>
    <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" @submit.prevent="submit">
      <div class="form-row">
        <p-text-field v-model="name" :label="'Name'" autocomplete="off" :disabled="isCreateRequestPending" />
        <div v-if="!$v.name.required" class="error">Name is required</div>
      </div>
      <div class="form-row">
        <p-tags v-model="aliases" :label="'Aliases'" :disabled="isCreateRequestPending" placeholder="" :options="[]" />
      </div>
      <div class="form-row">
        <p-tags v-model="domains" :label="'Domains'" :disabled="isCreateRequestPending" placeholder="" :options="[]" />
      </div>

      <div v-if="isCreateRequestFailed" class="error">{{ errorMessage }}</div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button color="primary" type="submit" :disabled="isCreateRequestPending || ($v.$anyDirty && $v.$invalid)">Save</p-button>
      </div>
    </form>

    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import TextField from '@/components/common/TextField';
import Tags from '@/components/common/Tags';
import Button from '@/components/common/Button';

export default {
  components: {
    'p-text-field': TextField,
    'p-tags': Tags,
    'p-button': Button
  },
  data() {
    return {
      name: '',
      aliases: [],
      domains: [],
      errorMessage: ''
    };
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.organizations.isCreateRequestPending,
      isCreateRequestFailed: s => s.organizations.isCreateRequestFailed
    })
  },
  validations: {
    name: {
      required
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        this.errorMessage = '';
        await this.$store.dispatch('organizations/create', {
          name: this.name.trim(),
          aliases: Array.isArray(this.aliases) ? this.aliases : [],
          domains: Array.isArray(this.domains) ? this.domains : []
        });
        this.$router.push({ path: `/organizations` });
      } catch (e) {
        this.errorMessage =
          e?.response?.status === 409 ? 'Organization already exists' : 'Failed to create a new organization. Check your input and try again.';
      }
    },
    back() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.create-organization {
  width: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
  form {
    padding: 2rem;
    max-width: 480px;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }
}
</style>
